import Swiper from "swiper";
import { Navigation, Pagination, Keyboard, A11y } from "swiper/modules";

/**
 * Initializes the hero image slider, mainly used on the home page
 */
class Carousel {
  static init() {
    //const sliders = document.querySelectorAll(".swiper");

    new Swiper(".swiper", {
      modules: [Navigation, Pagination, Keyboard, A11y],
      a11y: {
        enabled: true,
        scrollOnFocus: true,
      },
      loop: true,
      watchSlidesProgress: true,
      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },

      pagination: {
        el: ".swiper-pagination",
      },

      navigation: {
        nextEl: ".o-hero__next",
        prevEl: ".o-hero__prev",
      },
    });
  }
}

export default Carousel;
